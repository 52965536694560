import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Logout from "./Logout";
import { AuthContext } from "../contexts/AuthContext";
import Loader from "../components/Loader";

function Dashboard() {
  const [repos, setRepos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { authToken } = useContext(AuthContext);

  useEffect(() => {
    if (!authToken) {
      console.error("No access token available");
      return;
    }

    axios
      .get(`https://codesplain.onrender.com/user-repos`, { params: { token: authToken } })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setRepos(response.data);
        } else {
          console.error(
            "Unexpected response format or GitHub API error:",
            response.data
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching repositories:", error);
        setLoading(false);
      });
  }, [authToken]);

  const reposPerPage = 6;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const styles = {
    container: {
      backgroundColor: "#161724",
      padding: "30px",
      maxWidth: "80rem",
      minHeight: "50rem",
      margin: "30px auto",
    },
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",
      gap: "20px",
    },
    title: {
      fontSize: "2.5em",
      fontWeight: "600",
      color: "white",
      marginBottom: "40px",
      borderBottom: "2px solid #0366d6",
      paddingBottom: "10px",
    },
    repoCard: {
      backgroundColor: "#161724",
      border: "1px solid white",
      padding: "50px",
      borderRadius: "15px",
      maxWidth: "100%",
      height:"15rem",
      boxShadow: "0 2px 7px white",
      transition: "all 0.3s",
      "&:hover": {
        transform: "scale(1.05)",
      },
    },
    repoName: {
      fontSize: "1.2em",
      fontWeight: "600",
      color: "white",
      marginBottom: "10px",
      paddingLeft:"0.5rem",
      textDecoration: "none",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    button: {
      backgroundColor: "#222229",
      color: "white",
      padding: "8px 15px",
      textDecoration: "none",
      borderRadius: "6px",
      margin: "5px",
      marginTop:"3rem",
      display: "inline-block",
      transition: "background-color 0.3s, border-color 0.3s, transform 0.3s",
      border: "1px solid #d1d5da",
      "&:hover": {
        backgroundColor: "white",
        transform: "scale(1.05)",
      },
    },
  };

  const indexOfLastRepo = currentPage * reposPerPage;
  const indexOfFirstRepo = indexOfLastRepo - reposPerPage;
  const currentRepos = repos.slice(indexOfFirstRepo, indexOfLastRepo);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>My GitHub Repositories</h1>
      <Logout />
      {loading ? (
        <Loader />
      ) : (
        <div style={styles.gridContainer}>
          {currentRepos.map((repo) => (
            <div style={styles.repoCard} key={repo.id}>
              <a
                href={repo.html_url}
                target="_blank"
                rel="noopener noreferrer"
                style={styles.repoName}
              >
                {repo.name.length > 20
                  ? repo.name.slice(0, 20) + "..."
                  : repo.name}
              </a>
              <div>
                <a
                  href={repo.html_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.button}
                >
                  View
                </a>
                <a
                  href={`${repo.html_url}/archive/master.zip`}
                  style={styles.button}
                >
                  Download
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
      <Pagination
        reposPerPage={reposPerPage}
        totalRepos={repos.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
}

const Pagination = ({ reposPerPage, totalRepos, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRepos / reposPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginationStyles = {
    pagination: {
      listStyleType: "none",
      display: "flex",
      justifyContent: "center",
      padding: "25px",
    },
    pageItem: {
      margin: "0 5px",
    },
    pageLink: {
      padding: "5px 10px",
      textDecoration: "none",
      color: "white",
      border: "1px solid #d1d5da",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#e1e4e8",
        borderColor: "#0366d6",
        transform: "scale(1.05)",
      },
      "&.active": {
        backgroundColor: "#0366d6",
        color: "#ffffff",
        transform: "scale(1.1)",
      },
    },
  };

  return (
    <nav>
      <ul style={paginationStyles.pagination}>
        {pageNumbers.map((number) => (
          <li key={number} style={paginationStyles.pageItem}>
            <a
              onClick={(event) => {
                event.preventDefault();
                paginate(number);
              }}
              href="!#"
              style={paginationStyles.pageLink}
              className={number === currentPage ? "active" : ""}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Dashboard;
