import Failure from "./pages/failure/Failure";
import RouteIndex from "./pages/route/RouteIndex";
import Success from "./pages/success/Success";
import Upload from "./pages/upload/Upload";
import Upload2 from "./pages/upload2/Upload2"
import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DetailState from "./detailsState";
import Callback from "./components/Callback";
import Dashboard from "./components/Dashboard";
import { AuthProvider } from "./contexts/AuthContext";
export default function Home() {
  return (
    <>
    <DetailState>
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<RouteIndex />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/upload-step2" element={<Upload2 />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
      </AuthProvider>
      </DetailState>
    </>
  );
}
