import React from "react";
import "./Route.css";
function RouteIndex() {
  const handleGitLabOAuth = () => {
    window.location.href =
      "https://api.stytch.com/v1/public/oauth/github/start?public_token=public-token-live-b6deb8c5-1ad8-48e9-be91-b4124191c7fb";
  };
  const handleBitBucketOAuth = () => {
    window.location.href =
      "https://api.stytch.com/v1/public/oauth/github/start?public_token=public-token-live-b6deb8c5-1ad8-48e9-be91-b4124191c7fb";
  };
  const handleGithub = () => {
    const clientId = "9f1fec416f41e1a364c3";
    const redirectUri = encodeURIComponent("https://app.codesplain.live/callback");
    const scope = encodeURIComponent("repo");
    const githubUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
    
    window.location.href = githubUrl;
  };
  const handleReauthorize = () => {
    handleGithub();
  };

  const accessToken = localStorage.getItem("access_token");

  return (
    <>
      <div className="index">
        <h1>Get Started with Codesplain</h1>
        <h3>AI-powered documentation generation tool.</h3>
        <div className="buttons">
          <div className="button github">
            <button className="buttonButton githubButton" onClick={handleGithub}>
              <img src="./github-logo.png" alt="github icon" />
              <span className="routeButtonText">Login with Github</span>
            </button>
          </div>
          <div className="button bitbucket">
            <button
              className="buttonButton bitbucketButton"
              onClick={handleBitBucketOAuth}
            >
              <img src="./bitbucket-logo.png" alt="bitbucker icon" />
              <span className="routeButtonText"> Login with Bitbucket</span>
            </button>
          </div>
          <div className="button gitlab">
            <button
              className="buttonButton gitlabButton"
              onClick={handleGitLabOAuth}
            >
              <img src="./gitlab-logo.png" alt="gitlab icon" />
              <span className="routeButtonText">Login with Gitlab</span>
            </button>
          </div>
        </div>
      </div>
      {accessToken ? (
        <div>
          <h1>Welcome back! You are already logged in.</h1>
          <button onClick={handleReauthorize}>Reauthorize with GitHub</button>
        </div>
      ) : null}
    </>
  );
}

export default RouteIndex;
