import { useState } from "react";
import detailContext from "./detailsContext";
import React from "react"
const DetailState=(props)=>{
    const state={
        "fullName":"",
        "email":"",
        "phone":"",
        "fileName":""
    }
    const [details,setDetails]=useState(state)
    return <detailContext.Provider value={{details,setDetails}}>
        {props.children}
    </detailContext.Provider>
}
export default DetailState