import React from 'react'
import "./Success.css"
function Success() {
  // This is the Login file
  return (
    <>
    <div className='success'>
        <h1>Welcome to my Github App</h1>
        <form className='successForm'>
        <div className='formDiv'>
        <label for="username">Username</label>
        <input type="text" id="username" name="username" placeholder='Type your username'/>
        </div>
        <div className='formDiv'>
        <label for="email">Email Address</label>
        <input type="text" id="email" name="email" placeholder='email@example.com'/>
        </div>
        <div className='formDiv'>
        <label for="number">Phone Number</label>
        <input type="text" id="number" name="number" placeholder='+911234567890'/>
        </div>
        <button className="formSubmit" type="submit">Log into Github </button>
        </form>
    </div>
    </>
  )
}

export default Success