import React,{useContext} from 'react'
import detailContext from '../../detailsContext';
import "./upload2.css"
import AWS from 'aws-sdk';
import { useNavigate } from 'react-router-dom'


function Upload2() {
    const navigate=useNavigate()

    const {details,setDetails}=useContext(detailContext)

    function handleChange(e){
        const {name, value } = e.target
        setDetails ( prevState =>{
            return {
              ...prevState,
              [name]: value
          }
    })
    
}

    function handleSubmit(e){
        e.preventDefault()
        let payloadToUser={"Subject":"test email from lambda react","Body":"test after implementing context api body","To":details.email}
        let payloadToAdmin={"Subject":"test email from lambda react to the admin","Body":JSON.stringify(details),"To":details.email}
        
        const lambda = new AWS.Lambda({region:'us-east-1',accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY});
        const paramsUser = {
            FunctionName: 'SendEmailFunc',
            Payload: JSON.stringify(payloadToUser)
        };
        const paramsAdmin = {
            FunctionName: 'SendEmailFunc',
            Payload: JSON.stringify(payloadToAdmin)
        };

        lambda.invoke(paramsUser, (err, data) => {
            if (err) {
                console.log(err);
            } else {
                console.log(JSON.parse(data.Payload));
            }
        });
        lambda.invoke(paramsAdmin, (err, data) => {
            if (err) {
                console.log(err);
            } else {
                console.log(JSON.parse(data.Payload));
            }
        });

        navigate("/file_upload_done")
        }


  return (
    <>
    <div className='uploadDiv'>
    <h1>Step 3: Contact Information! </h1>
    <p>Please provide us with your contact details</p>
    <form onSubmit={handleSubmit}>
    <div className="contactDetails">
        
        <input  className="contactInput" name='fullName' value={details.fullName} onChange={handleChange} type="text" placeholder='Full name' required autoComplete="off" />
        <input className="contactInput" name='email'  value={details.email}  onChange={handleChange} type="text" placeholder='Email address' required autoComplete="off"/>
        <input  className="contactInput" name='phone' value={details.phone}  onChange={handleChange} type="text" placeholder='Phone number' required autoComplete="off"/>
        <button className="contactButton" type='submit'>GENERATE DOCUMENTATION</button>
        
    </div>
    </form>

    </div>
    </>
  )
}

export default Upload2