import React from "react";

const Logout = () => {
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
    window.location.href = "/";
  };

  const styles = {
    button: {
      backgroundColor: "#fafbfc",
      color: "#24292e",
      padding: "8px 16px",
      borderRadius: "6px",
      border: "1px solid #d1d5da",
      cursor: "pointer",
      transition: "background-color 0.3s, border-color 0.3s",
      fontSize: "1em",
      fontWeight: "500",
      '&:hover': {
        backgroundColor: "#f3f4f6",
        borderColor: "#0366d6",
      }
    }
  };

  return (
    <div>
      <button style={styles.button} onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Logout;
