import React from 'react'
import {useLocation} from "react-router-dom";
import "./Failure.css"
import { Link } from 'react-router-dom';
function Failure() {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  return (
    <>
    <div className='failureDiv'>
    <p>{token}</p> 
    <p className='unablePara'><i>Unable to fetch codebase! Please upload the code to generate Codesplain documentation.</i></p>
      <h1 className = 'failureHeader'>AI documentation for codebase knowledge management</h1>
      <div>
         <p className='examplePara'>
        Examples: 
        <a href='https://ndaxfree.codesplain.live/' target='blank'>C# Demo</a>,&nbsp;
        <a href='https://erust.codesplain.live/' target='blank'>Rust Demo</a>,&nbsp;
        <a href='https://pyflask.codesplain.live/' target='blank'>Python Demo</a>,&nbsp;
        <a href='https://ethereum-wallet.codesplain.live/'  target='blank' >HD Wallet Demo </a>,&nbsp;
        </p>
        <br/>
      </div>
      
      <Link to="/upload"><button className='failureButton'>GENERATE DOCUMENTATION</button></Link>
    </div>
    </>
  )
}

export default Failure