import FileStack from './FileStack'
import React, { useContext, useState } from 'react'
import "./Upload.css"
import detailContext from '../../detailsContext'
import { Link, useNavigate } from 'react-router-dom'
function Upload() {
  const { setDetails } = useContext(detailContext)
  const navigate = useNavigate()
  const [showPicker, setShowPicker] = useState(false)
  const [filename, setFileName] = useState({})

  function handleFileName() {
    setDetails(prevState => {
      return {
        ...prevState,
        "fileName": filename.filename

      }
    })
  }

  return (
    <>
      <div className='uploadDiv'>
        {filename.filename ? <h1>Step 2: Upload Done!</h1> : <h1>Step 1: Manual Upload!</h1>}
        {filename.filename ? <p><i>Your file has been uploaded click "Next" to go to the next step</i></p> : <p>Please upload your your file manually to generate AI documentation</p>}
        <div onClick={() => setShowPicker(!showPicker)} className='dragAndDropBoxDiv'>
          {!filename.filename && <div className='dragAndDropBox'><p className="dragAndDropBoxP">Drag & Drop or Upload repository to create documentation</p></div>}
          {filename.filename && <p>Filename : <b>{filename.filename}</b></p>}
          {/* {filename.filename?<p>How to upload repository</p>:<p></p>} */}

        </div>
         {!filename.filename && <Link style={{textDecoration:'none', fontSize:'12px',borderBottom:'0.5px solid black'}} to="https://youtu.be/W17dY1Yg4lM" target="_blank">Watch how to upload repository</Link>}
        <div>
          <button className='uploadButton' onClick={() => { filename.filename ? navigate("/upload-step2") : setShowPicker(!showPicker); handleFileName() }}>{filename.filename ? <span>NEXT</span> : <span>UPLOAD REPOSITORY</span>}</button>
          {showPicker && <FileStack setFileName={setFileName} setShowPicker={setShowPicker}></FileStack>}
        </div>
      </div>
    </>
  )
}

export default Upload