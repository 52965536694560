import React from 'react'
import { PickerOverlay } from 'filestack-react'

function FileStack({setFileName,setShowPicker}) {
    const apiKey="AmjobqdmJSMunMCxKvdi8z"
    

     const options = {
  maxSize: 30* 1024 * 1024, // limit file size to 30MB
  accept: ['.zip', '.tar'], // limit file type to images only
  fromSources:["local_file_system"]
};

  return (
    <div>
        <PickerOverlay
        apikey={apiKey}
        pickerOptions={options}

        onSuccess={(res) =>{ 
          console.log(res)
          setFileName(res.filesUploaded[0])}}
        onUploadDone={(res) => {
            setShowPicker(false)

        }}
        onError={(error)=> console.log(error)}
        
/>

    </div>
  )
}

export default FileStack