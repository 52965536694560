import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { AuthContext } from "../contexts/AuthContext";
import Loader from "./Loader";

function Callback() {
  const navigate = useNavigate();
  const { setAuthToken } = useContext(AuthContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    axios.post("https://codesplain.onrender.com/get-token", { code })
      .then((response) => {
        const accessToken = response.data.access_token;
        setAuthToken(accessToken);
        
        localStorage.setItem("accessToken", accessToken);

        navigate("/dashboard");
      })
      .catch((error) => {
        console.error("Error during authentication:", error);
      });
  }, [navigate, setAuthToken]);

  return <div><Loader/></div>;
}

export default Callback;
